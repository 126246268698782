import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import PageStepForm, { PageStepFormDataElement } from "../../organisms/page-step-form/PageStepForm";
import { PetSpecies } from "../../settings/pet";
import { Events, track } from "../../utils/analytics";
import { rollbar } from "../../utils/rollbar";
import * as styles from "./Species.module.scss";

interface PetSpeciesFormValues {
  petSpecies: PetSpecies | null;
}

interface Image {
  default: string;
}

const petSpeciesSVG: Image =
  require("../../features/leads-funnel/ui/images/petSpecies.svg") as Image;

const petSpeciesSVGSrc: string = petSpeciesSVG.default;

const Species = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const setLead = useSetLead();
  const nextUrl = `/${i18n.language}/onboarding/sex/`;
  const PetSpeciesFormData: PageStepFormDataElement = {
    initialValues: {
      petSpecies: null,
    },
    handleSubmit: (
      values: PetSpeciesFormValues,
      { setSubmitting }: FormikHelpers<PetSpeciesFormValues>
    ): void => {
      if (!values.petSpecies) {
        return;
      }

      setSubmitting(true);
      setLead(values)
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          track(Events.FORM_ANSWERED, {
            question: "Pet species",
            answer: values.petSpecies,
          });

          setTimeout(() => navigate(nextUrl), 500);
        })
        .catch((): void => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PetSpeciesFormValues>) => {
      const { isSubmitting, handleSubmit } = props;

      return (
        <Form className={cn(styles.petSpeciesForm)} onChange={() => handleSubmit()}>
          <Field
            type="radio"
            name="petSpecies"
            value={PetSpecies.dog}
            id="dog"
            disabled={isSubmitting}
          />
          <label htmlFor="dog">{t("onboarding.species.field.dog")}</label>
          <Field
            type="radio"
            name="petSpecies"
            value={PetSpecies.cat}
            id="cat"
            disabled={isSubmitting}
          />
          <label htmlFor="cat">{t("onboarding.species.field.cat")}</label>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("onboarding.species.title")}
      image={petSpeciesSVGSrc}
      formData={PetSpeciesFormData}
    />
  );
};
export default Species;
