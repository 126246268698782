import { graphql } from "gatsby";

import OnboardingLayout from "../../organisms/lead/OnboardingLayout";
import Species from "../../organisms/lead/Species";

const SpeciesPage = (): JSX.Element => (
  <OnboardingLayout>
    <Species />
  </OnboardingLayout>
);

export default SpeciesPage;

export const query = graphql`
  query SpeciesPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
